export enum RecordTimeErrorCode {
    UNKNOWN_PIN = 70,
    EXISTING_OPEN_RECORDED_ASSIGNMENT = 426,
    NO_OPEN_RECORDED_ASSIGNMENT = 420,
    RECORD_END_TIME_BEFORE_START_TIME = 421,
    CLOCKING_INTO_OTHER_STRUCTURENODE_NOT_ALLOWED = 434,
    EXPIRED_WORK_PERMIT = 435,
    EXPIRED_RESIDENCE_PERMIT = 436,
    NO_VALID_WORKING_CONTRACT = 437,
    UNDERAGE_CLOCKING_IN_ON_SPECIFIC_HOLIDAYS_NOT_ALLOWED = 438,
    CLOCKING_IN_DURING_REST_PERIOD_NOT_ALLOWED = 470,
}
