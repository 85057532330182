<template>
    <info-modal v-model:is-open="isOpen" :title-icon="happy" :title="t('timeRecordingSuccess.title', { employeeName })">
        <template #body>
            <table class="w-full border-collapse">
                <thead>
                    <tr>
                        <th class="border-2 border-neutral-500 p-4 dark:border-neutral-200">
                            <div class="flex items-center"><ion-icon class="mr-2 text-2xl" :icon="logIn" />{{ t("timeRecordingSuccess.tableHeaderColumnStartTime") }}</div>
                        </th>
                        <th class="border-2 border-neutral-500 p-4 dark:border-neutral-200">
                            <div class="flex items-center"><ion-icon class="mr-2 text-2xl" :icon="logOut" />{{ t("timeRecordingSuccess.tableHeaderColumnEndTime") }}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in props.recordedAssignments" :key="item._id">
                        <td v-if="item.startTime" class="border-2 border-neutral-500 p-2 xl:p-4 dark:border-neutral-200">{{ $d(item.startTime, "time") }}</td>
                        <td v-else class="border-2 border-neutral-500 p-2 xl:p-4 dark:border-neutral-200">-</td>

                        <td v-if="item.endTime" class="border-2 border-neutral-500 p-2 xl:p-4 dark:border-neutral-200">{{ $d(item.endTime, "time") }}</td>
                        <td v-else class="border-2 border-neutral-500 p-2 xl:p-4 dark:border-neutral-200">-</td>
                    </tr>
                </tbody>
            </table>
        </template>
        <template #footer>
            <button class="rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-neutral-100" @click="isOpen = false">
                {{ t("close") }}
            </button>
        </template>
    </info-modal>
</template>

<script setup lang="ts">
    import InfoModal from "@/components/modals/InfoModal.vue";
    import { RecordedAssignmentWithEmployeeNameDto } from "@/services/api/services";
    import { happy, logIn, logOut } from "ionicons/icons";
    import { computed } from "vue";
    import { useI18n } from "vue-i18n";

    const { t } = useI18n({ useScope: "global" });

    const isOpen = defineModel("isOpen", { type: Boolean, required: true });

    const props = defineProps({
        recordedAssignments: {
            type: Array as () => RecordedAssignmentWithEmployeeNameDto[],
            default: () => [],
        },
    });

    const employeeName = computed(() => {
        if (!props.recordedAssignments || props.recordedAssignments.length == 0) return undefined;
        // we can take the employee name of the first recorded assignment because all recorded assignments must have the same employee name
        return props.recordedAssignments[0].employeeName;
    });
</script>
